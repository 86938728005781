export enum DropDownType {
  LocationTypes = 'LocationTypes',
  Cities = 'Cities',
  LocationClasses = 'LocationClasses',
  PermissionPeriodTypes = 'PermissionPeriodTypes',
  VisaTypes = 'VisaTypes',
  MaintenanceSystemTypes = 'MaintenanceSystemTypes',
  CaptureTypes = 'CaptureTypes',
  DrivingLicenseTypes = 'DrivingLicenseTypes',
  Units = 'Units',
  Categories = 'Categories',
  StolenItemCategories = 'StolenItemCategories',
  StolenItemUnits = 'StolenItemUnits',
  Places = 'Places',
  AbsenceReasons = 'AbsenceReasons',
  FuelTypes = 'FuelTypes',
  SupportiveAgencies = 'SupportiveAgencies',
  ProjectTypes = 'ProjectTypes',
  AssetTypes = 'AssetTypes',

  OperationArea = 'OperationArea',
  PermissionRequestTypes = 'PermissionRequestTypes',
  PermissionRequestStates = 'PermissionRequestStates',
  IdentityTypes = 'IdentityTypes',
  OneTimeInputOutputDeclarationTypes = 'OneTimeInputOutputDeclarationTypes',
  DriverTypes = 'DriverTypes',
  TaskRequestTypes = 'TaskRequestTypes',
  TaskStatus = 'TaskStatus',
  Nationalities = 'Nationalities',
  MaterialTypes = 'MaterialTypes',
  VisitorTypes = 'VisitorTypes',
  ExecutionDecisionTypes = 'ExecutionDecisionTypes',
  TemporaryEntryItemTypes = 'TemporaryEntryItemTypes',
  NoteExecutionDecisionTypes = 'NoteExecutionDecisionTypes',
  RiskLevels = 'RiskLevels',
  SecurityNoteStates = 'SecurityNoteStates',
  AssaultType = 'AssaultType',
  EventType = 'EventType',
  IncidentStates = 'IncidentStates',
  IncidentTypes = 'IncidentTypes',
  IncidentPersonTypes = 'IncidentPersonTypes',
  SecurityReportStates = 'SecurityReportStates',
  SecurityReportTypes = 'SecurityReportTypes',
  SeverityLevels = 'SeverityLevels',
  FuelTruckOutStatuses = 'FuelTruckOutStatuses',
  FenceDirections = 'FenceDirections',
  PeopleAndVehicleEntryTypes = 'PeopleAndVehicleEntryTypes',
  InspectionItemTypes = 'InspectionItemTypes',
  GeneralDescriptions = 'GeneralDescriptions',
  GuardRoomDescriptions = 'GuardRoomDescriptions',
  SysGatesDescriptions = 'SysGatesDescriptions',
  SysFencesDescriptions = 'SysFencesDescriptions',
  SysMainBuildingsDescriptions = 'SysMainBuildingsDescriptions',
  SupportiveSecurityAgenciesDescriptions = 'SupportiveSecurityAgenciesDescriptions',
  SecurityAgenciesTypes = 'SecurityAgenciesTypes',
  AgenciesAvailableEquipments = 'AgenciesAvailableEquipments',
  SurveillanceRoomDescriptions = 'SurveillanceRoomDescriptions',
  PatrolsDescriptions = 'PatrolsDescriptions',
  FeedbackTypes = 'FeedbackTypes',
  FeedbackStatuses = 'FeedbackStatuses',
  SystemAdminTypes = 'SystemAdminTypes',
  ServiceAndRequestStates = 'ServiceAndRequestStates',
  Provinces = 'Provinces',
  GateArmingRequestStates = 'GateArmingRequestStates',
  SecurityManArmingRequestStates = 'SecurityManArmingRequestStates',
  SecurityMenTaskTypes = 'SecurityMenTaskTypes',
  SecurityMenTaskStatuses = 'SecurityMenTaskStatuses',
  CourseTypes = 'CourseTypes',
  SafeItemTypes = 'SafeItemTypes',
  SafeItemCondition = 'SafeItemCondition',
  SafeStatuses = 'SafeStatuses',
  WeaponTypes = 'WeaponTypes',
  AmmunitionTypes = 'AmmunitionTypes',
  CartridgeTypes = 'CartridgeTypes',
  WeaponGrade = 'WeaponGrade',
}
